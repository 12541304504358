import React, { useState, useRef } from 'react'
import * as Formats from './formats.js'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from "react-bootstrap/Tooltip"
import 'bootstrap/dist/css/bootstrap.min.css';
import './grisling.css';
const { v4: uuidv4 } = require('uuid');

function DiscountTooltip(props) {
	const undiscountedCost = props.undiscountedCost
	const discountCodesArray = props.discountCodes
	let imploded = discountCodesArray.join(", ")
	let tt = props => (
		<Tooltip {...props}>{imploded}</Tooltip>
	)

	return (
		<OverlayTrigger placement="top" overlay={tt}>
			<span style={{ textDecoration: 'line-through' }}>{undiscountedCost}</span>
		</OverlayTrigger>
	)
}

function ContractStepItem(props) {
	const step = props.step
	const contract = step.contract
	const variant = step.variant

	const [expanded, setExpanded] = useState(false)
	const target = useRef(null)

	const [allocatedAdults, setAllocatedAdults] = useState(step.allocated_adults ?? props.max_adults)
	const [allocatedChildren, setAllocatedChildren] = useState(step.allocated_children ?? props.max_children)
	const [useAllocation, setUseAllocation] = useState(step.allocated_adults || step.allocated_children)

	const avatar = props.index+1
//	const avatar = props.avatar
	const name = contract.name
	const is_duration_sensitive = contract.is_duration_sensitive || contract.num_days
	const currency = step.currency || 'WTF'

	const showPrice = props.calculation_valid
	const price = step.salePriceCcy == null ? '' : Formats.formatPrice(step.salePriceCcy)
	const showUndiscountedPrice = showPrice &&
		step.salePriceCcy &&
		step.undiscountedSalePriceCcy &&
		step.salePriceCcy !== step.undiscountedSalePriceCcy
	const undiscounted_price = step.undiscountedSalePriceCcy == null ? '' : Formats.formatPrice(step.undiscountedSalePriceCcy)
	const showMargin = showPrice && step.marginCcy

	const margin = step.marginCcy == null ? '' : Formats.formatPrice(step.marginCcy)
	const cost = step.saleCost == null ? '' : Formats.formatPrice(step.saleCost)
	const showUndiscountedCost = showPrice && step.saleCost && step.undiscountedSaleCost && step.saleCost !== step.undiscountedSaleCost
	const undiscountedCost = step.undiscountedSaleCost == null ? '' : Formats.formatPrice(step.undiscountedSaleCost)

	const handleContextMenu = (evt) => {
		if (props.contextMenuHandler) {
			// TODO we need to do something better here... this is a chaos.
			props.contextMenuHandler(step, props.index, evt)
		}
	}

	const betterName = name => name ? name : 'Basis'

	//const printVariants = contract => contract.variant_names && contract.variant_names.length > 1 // it's not an array??
	const printVariants = contract => {
		// console.log(Array.isArray(contract.variant_names));  // false. Well we're busted then.
		return contract.variant_names && (contract.variant_names[0] || contract.variant_names[1])
	}

	const variantsMenu = contract => {
		if (!printVariants(contract)) return ''
		let dims = contract.variant_names
		if (Array.isArray(dims) && dims.length === 1 && dims[0] == '') dims = { 0: '' };
		return (
			<>
				<Button ref={target} className="variant-button" variant='secondary' onClick={() => setExpanded(!expanded)}>
					{betterName(variant)}
				</Button>
				<Overlay target={target.current} show={expanded} placement="left">
					{({ placement, arrowProps, show: _show, popper, ...iprops }) =>
						<div {...iprops} style={{
							backgroundColor: 'white',
							border: '1px solid black',
							...iprops.style
						}}>
							{
								/*
								  Because PHP decided to json serialize [0=>"foo",1=>"bar"] to [0:"foo",1:"bar"], but [0=>""] to [""], we can't do map. Fuck.
								*/
								Object.keys(dims).map(
									(name, index) => {
										return (
											<Button key={index} className="variant-item" onClick={() => { props.variantSelectionHandler(step, dims[name]); setExpanded(false) }} variant='link'>{betterName(dims[name])}</Button>
										)
									}
								)
							}
						</div>
					}
				</Overlay>
			</>
		)
	}

	const allocatedAdultsChanged = (evt) => {
		let adults = evt.target.value
		setAllocatedAdults(adults)
		props.handleAnyStepInput(evt)
		if (adults == '0' && allocatedChildren == '0') setUseAllocation(false)
	}

	const allocatedChildrenChanged = (evt) => {
		let children = evt.target.value
		setAllocatedChildren(children)
		props.handleAnyStepInput(evt)
		if (allocatedAdults == '0' && children == '0') setUseAllocation(false)
	}

	const handleAllocationOn = (evt) => {
		// Fire dummy events to get the numbers set.
		props.handleAnyStepInput({ target: { name: 'allocated_adults', value: allocatedAdults } })
		props.handleAnyStepInput({ target: { name: 'allocated_children', value: allocatedChildren } })
		// display the controls instead of the button.
		setUseAllocation(true)
	}

	const allocationMenu = () => {
		if (useAllocation) return (
			<>
				<label>voksne <input type="number" onChange={allocatedAdultsChanged} name="allocated_adults" value={allocatedAdults} min='0' max={props.max_adults} className='days' /></label>
				<label>børn <input type="number" onChange={allocatedChildrenChanged} name="allocated_children" value={allocatedChildren} min='0' max={props.max_children} className='days' /></label>
			</>)
		else return (
			<Button className="variant-button" variant='dark' onClick={handleAllocationOn}>
				Alle
			</Button>
		)
	}

	return (
		<Draggable
			draggableId={step.id.toString()}
			index={props.index}>
			{(provided, snapshot) => (
				<div className='row step'
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					onContextMenu={handleContextMenu}
				>
					<div className="col-sm-1">
						<span className="w-40 avatar gd-step">{avatar}</span>
					</div>

					<div className="col-sm-3">
						{name}
						&#32;
						{variantsMenu(contract)}
						&#32;
						{allocationMenu()}
					</div>

					<div className="col-sm-3">
						<label>Dag#<input type="number" onChange={props.handleAnyStepInput} name="day" value={step.day} min='0' max='99' className='days' /></label>
						{is_duration_sensitive && <label>Antal<input type="number" onChange={props.handleAnyStepInput} value={step.num_days} min='0' max='99' name="num_days" className='days' /></label>}
					</div>
					<div className="col-sm-2">
						{step.firstDate != null && props.calculation_valid ? Formats.formatDate(step.firstDate) : ''}
						{step.lastDate != null && props.calculation_valid ? (String.fromCharCode(8211) + Formats.formatDate(step.lastDate)) : ''}
												
						<br />
						{props.calculation_valid ? step.productNames : ''}
					</div>
					<div className="col-sm-1 price">
						{showPrice && <>{currency}<br />{cost}</>}
						{showUndiscountedCost ? <>
							<br />
							<DiscountTooltip undiscountedCost={undiscountedCost} discountCodes={props.step.discountCodes} />
						</> : ''}
					</div>
					<div className="col-sm-1 price">
						{showMargin ? <>{props.currency}<br />{margin}</> : ''}
					</div>
					<div className="col-sm-1 price">
						{showPrice ? <>{props.currency}<br />{price}</> : ''}
						{showUndiscountedPrice ? <><br />
							<DiscountTooltip undiscountedCost={undiscounted_price} discountCodes={props.step.discountCodes} />
						</> : ''}
					</div>
				</div>
			)}
		</Draggable>
	)
}

const dolphinSegmentTypeMenu = (target, expanded, setExpanded, step, handler) => {
	const dims = ['Ikke i Dolphin', 'Vehicle', 'Accommodation', 'Insurance', 'Tour', 'Rail', 'Cruise', 'Other']
	const segment_type = step.dolphin_segment_type ?? 'Ikke i Dolphin'
	return (
		<>
			<Button ref={target} className="variant-button" variant='primary' onClick={() => setExpanded(!expanded)}>
				{segment_type}
			</Button>
			<Overlay target={target.current} show={expanded} placement="left">
				{({ placement, arrowProps, show: _show, popper, ...iprops }) =>
					<div {...iprops} style={{
						backgroundColor: 'white',
						border: '1px solid black',
						...iprops.style
					}}>
						{
							Object.keys(dims).map(
								(name, index) => {
									return (
										<Button key={index} className="variant-item" onClick={() => { handler(step, dims[name]); setExpanded(false) }} variant='link'>{dims[name]}</Button>
									)
								}
							)
						}
					</div>
				}
			</Overlay>
		</>
	)
}

function FixedPriceStepItem(props) {
	const step = props.step
    const avatar = props.index + 1
//	const avatar = props.avatar
    const currency = step.currency || 'DKK'
    const showPrice = props.calculation_valid
    const priceCurrency = props.currency

	// is this cost or is this price??? Its tooootally fukt here. And  what ccy anyway??
	const price = step.salePriceCcy == null ? '' : Formats.formatPrice(step.salePriceCcy)
	const undiscounted_price = step.undiscountedSalePriceCcy == null ? '' : Formats.formatPrice(step.undiscountedSalePriceCcy)

	const showUndiscountedPrice = showPrice && step.salePriceCcy && step.undiscountedSalePriceCcy && step.salePriceCcy !== step.undiscountedSalePriceCcy

	const cost = step.saleCost == null ? '' : Formats.formatPrice(step.saleCost)
	const undiscountedCost = step.undiscountedSaleCost == null ? '' : Formats.formatPrice(step.undiscountedSaleCost)
	const showUndiscountedCost = showPrice && step.saleCost && step.undiscountedSaleCost && step.saleCost !== step.undiscountedSaleCost
	const showMargin = showPrice && step.marginCcy

	const margin = step.marginCcy == null ? '' : Formats.formatPrice(step.marginCcy)

	const handleContextMenu = (evt) => {
		if (props.contextMenuHandler) {
			// TODO we need to do something better here... this is a chaos.
			props.contextMenuHandler(step, props.index, evt)
		}
	}
    
  	const [expanded, setExpanded] = useState(false)
	const target = useRef(null)

	const dolphinSegmentTypeSelectionHandler = (step, item) => {
		step.dolphin_segment_type = item
	}

	return (
		<Draggable
			draggableId={step.id.toString()}
			index={props.index}
		>
			{(provided, snapshot) => (
				<div className='row step'
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					onContextMenu={handleContextMenu}
				>
					<div className="col-sm-1">
						<span className="w-40 avatar gd-step">{avatar}</span>
					</div>

					<div className="col-sm-6"><input placeholder='Navn' type='text' value={step.name} onChange={props.handleAnyStepInput} name='name' />
						<select name='currency' onChange={props.handleAnyStepInput} value={currency}>
							{props.currencyList.map((currency, idx) =>
								<option key={idx} value={currency}>{currency}</option>
							)}
						</select>
						<input type="number" onChange={props.handleAnyStepInput} name="cost" value={step.cost} min='0' className='price' />
						<label>Dag#<input type="number" onChange={props.handleAnyStepInput} name="day" value={step.day} min='0' max='99' className='days' /></label>
						<label>Antal<input type="number" onChange={props.handleAnyStepInput} value={step.num_days} min='0' max='99' name="num_days" className='days' /></label>

						{'pis'} {dolphinSegmentTypeMenu(target, expanded, setExpanded, step, dolphinSegmentTypeSelectionHandler)}


					</div>

					<div className="col-sm-2">
						{step.firstDate == null || !props.calculation_valid ? '' : Formats.formatDate(step.firstDate)}
					</div>
					<div className="col-sm-1 price">
						{showPrice ? <>{currency}<br />{cost}</> : ''}
						{showUndiscountedCost ? <><br /><span style={{ textDecoration: 'line-through' }}>{undiscountedCost}</span></> : ''}
					</div>

					<div className="col-sm-1 price">
						{showMargin ? <>{priceCurrency}<br />{margin}</> : ''}
					</div>
					<div className="col-sm-1 price">
						{showPrice ? <>{priceCurrency}<br />{price}</> : ''}
						{showUndiscountedPrice ? <><br /><span style={{ textDecoration: 'line-through' }}>{undiscounted_price}</span></> : ''}
					</div>
				</div>
			)}
		</Draggable>
	)
}

function PerPersonTemplateStepItem(props) {
	const step = props.step
	const currency = step.currency || ''
	const showPrice = props.calculation_valid
	const priceCurrency = props.currency
	const price = step.salePriceCcy == null || !showPrice ? '' : Formats.formatPrice(step.salePriceCcy)
	const cost = step.saleCost == null || !showPrice ? '' : Formats.formatPrice(step.saleCost)
	const showUndiscountedCost = showPrice && step.saleCost && step.undiscountedSaleCost && step.saleCost !== step.undiscountedSaleCost
	const undiscountedCost = showUndiscountedCost ? Formats.formatPrice(step.undiscountedSaleCost) : ''
	const margin = showPrice ? Formats.formatPrice(step.marginCcy) : ''

	const handleContextMenu = (evt) => {
		if (props.contextMenuHandler) {
			// TODO we need to do something better here... this is a chaos.
			props.contextMenuHandler(step, props.index, evt)
		}
	}
	return (
		<Draggable
			draggableId={step.id.toString()}
			index={props.index}
		>
			{(provided, snapshot) => (
				<div className='row step'
					onContextMenu={handleContextMenu}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}>
					<div className="col-sm-1">
						<span className="w-40 avatar gd-step">PP</span>
					</div>

					<div className="col-sm-8">
						{<input placeholder='Navn' type='text' value={step.name} onChange={props.handleAnyStepInput} name='name' />}
						<label>Omkostning {currency}&nbsp;
							<input type="number" onChange={props.handleAnyStepInput} name="cost" value={step.cost} min='0' className='price' />
						</label>&nbsp;
						<label>DB {currency}&nbsp;
							<input type="number" onChange={props.handleAnyStepInput} name="profit" value={step.profit} min='0' className='price' />
						</label>
					</div>

					<div className="col-sm-1 price">
						{showPrice ? <>{currency}<br />{cost}</> : ''}
						{showUndiscountedCost ? <><br /><span style={{ textDecoration: 'line-through' }}>{undiscountedCost}</span></> : ''}
					</div>

					<div className="col-sm-1 price">{showPrice ? <>{priceCurrency}<br />{margin}</> : ''}</div>
					<div className="col-sm-1 price">
						{showPrice ? <>{priceCurrency}<br />{price}</> : ''}
					</div>
				</div>
			)}
		</Draggable>
	)
}

export function TotalsRow(props) {
	if (props.totals == null || !props.calculation_valid) return "";
	const style = props.totals.isValid ? {} : { textDecoration: 'line-through' }
	const price = Formats.formatPrice(props.totals.salePriceCcy);
	const uprice = Formats.formatPrice(props.totals.undiscountedSalePriceCcy);
	const margin = Formats.formatPrice(props.totals.marginCcy);
	const upp = Formats.formatPrice(props.totals.perPersonUndiscountedSalePriceCcy);
	const pp = Formats.formatPrice(props.totals.perPersonSalePriceCcy);
	var breakdown = '';
	var needsComma = false;
	if(props.totals.costByCurrencies) {
		Object.keys(props.totals.costByCurrencies).map(function(ccy, i) {
			if(needsComma) breakdown += ', '; else needsComma = true;
		breakdown += ccy + ':' + props.totals.costByCurrencies[ccy];
	});
	}
	return (
		<div className='row totals'>
			<div className='col-sm-12' style={style}>
				I alt {props.totals.currency} {price} (uden rabat: {props.totals.currency} {uprice}). PP {props.totals.currency} {pp} (uden rabat: {upp}). DB {props.totals.currency} {margin}.<br/>
				<i>{breakdown}</i>
			</div>
		</div>
	)
}

function StepsList(props) {
	// These really are contracts... Might wanna change that when we have input.
	const steps = props.steps;

	let isSameContract = (step1, step2) => {
		if (!step1 || !step2) return false
		if (step1.type == 'ContractStep' && step2.type == 'ContractStep')
			return step1.contract.id == step2.contract.id
		if (step1.type == 'FixedPriceStep' && step2.type == 'FixedPriceStep')
			return step1.name == step2.name
		return false
	}

	if (steps) {
		let avatar_index = 0
		let avatar_suffix = 0
		let prev_step = null
		let avatar = ''
		let day = 0;

		steps.forEach((step, index) => {
			if (step.price == null) step.price = 1000;
			if (step.currency == null) step.currency = 'DKK';
		});

		return (<>
			<Droppable droppableId={props.droppable_id}>
				{(provided, snapshot) =>
				(
					<div id="steps_list" className="grisling_padded" ref={provided.innerRef}>
						{steps.map((step, index) => {
							switch (step.type) {
								case 'PerPersonTemplateStep':
									avatar_suffix = 0
									prev_step = step
									return <PerPersonTemplateStepItem
										step={step}
										key={step.id}
										index={index}
										avatar={avatar_index}
										calculation_valid={props.calculation_valid}
										handleAnyStepInput={e => props.handleAnyStepInput(index, e)}
										contextMenuHandler={props.contextMenuHandler}
										currency={props.currency}
									/>
								case 'ContractStep':
									if (step.day == null) step.day = day;
									if (isSameContract(prev_step, step)) {
										avatar_suffix++
									} else {
										avatar_index++
										avatar_suffix = 0
										day = day + parseInt(step.num_days)
									}
									if (step.num_days == null) step.num_days = step.contract && step.contract.is_duration_sensitive ? (step.contract.num_days ?? 1) : 0; // Apparently, this being set makes the input managed.
									// hey we can use default days.
									avatar = avatar_index + '' + (avatar_suffix ? String.fromCharCode(96 + avatar_suffix) : '')
									prev_step = step
									return <ContractStepItem
										step={step}
										key={step.id}
										index={index}
										avatar={avatar}
										calculation_valid={props.calculation_valid}
										handleAnyStepInput={e => props.handleAnyStepInput(index, e)}
										contextMenuHandler={props.contextMenuHandler}
										variantSelectionHandler={props.variantSelectionHandler}
										currency={props.currency}
										max_adults={props.num_adults}
										max_children={props.num_children}
									/>
								default:
									if (step.day == null) step.day = day;
									// if (!step.num_days) step.num_days = !step.contract || step.contract.is_duration_sensitive ? (step.contract.num_days ?? 1) : 0; // Apparently, this being set makes the input managed.
									if (isSameContract(prev_step, step)) {
										avatar_suffix++
									} else {
										avatar_index++
										avatar_suffix = 0
										day = day + parseInt(step.num_days);
									}
									// hey we can use default days.
									avatar = avatar_index + '' + (avatar_suffix ? String.fromCharCode(96 + avatar_suffix) : '')
									prev_step = step
									return <FixedPriceStepItem
										step={step}
										key={step.id}
										index={index}
										avatar={avatar}
										handleAnyStepInput={e => props.handleAnyStepInput(index, e)}
										calculation_valid={props.calculation_valid}
										currency={props.currency}
										currencyList={props.currencyList}
									/>
							}
						})}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</>
		)
	} else return '';
}

export default StepsList;

