import 'bootstrap/dist/css/bootstrap.min.css'
import './grisling.css'
import TripEditor from './tripEditor.js'
import React from "react";
import ReactDOM from "react-dom";


import Grisling from './grisling'
import { StrictMode } from "react";
ReactDOM.render(
  <StrictMode>
    <Grisling />
  </StrictMode>,
  document.getElementById('root')
);

//import config from './config'
//ReactDOM.render(
//<TripEditor config={config}/>, document.getElementById('root')
//<LoginPage>
//);
