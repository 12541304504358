/*
import {
    Navigate
  } from "react-router-dom";
*/
import TripEditor from './tripEditor'
import config from './config'
import TokenUtil from './TokenUtil';
import Login from './Login'
import React, {useState} from "react";

// https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications

  function Grisling() {
    return <TripEditor config={config}/>
  /*
    const [dumy, setDummy] = useState([]);
    if(TokenUtil.getToken()) {
      return <TripEditor config={config}/>
    } else {
      return <Login setToken={token=>{TokenUtil.setToken(token); setDummy('foo')}} />
    }
  */
  }
  
  /*
  function PrivateRoute({ children }) {
    let auth = useAuth();
    return auth ? children : <Navigate to="/login" />;
  }
  
  function useAuth() {
    // make a fetch test??
    return true
  }
  */
  
  export default Grisling;