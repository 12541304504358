export const LocalDate = require("@js-joda/core").LocalDate;
export const LocalDateTime = require("@js-joda/core").LocalDateTime;
export const DateTimeFormatter = require("@js-joda/core").DateTimeFormatter;

export const ISO_DATE_FORMAT = DateTimeFormatter.ofPattern('yyyy-MM-dd');
export const DK_DATE_FORMAT = DateTimeFormatter.ofPattern('d/MM yyyy');

export const ISO_DATETIME_FORMAT = DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm');
export const DK_DATETIME_FORMAT = DateTimeFormatter.ofPattern('d/MM yyyy HH:mm');

export function formatPrice(price) {
	  return price == null ? '-' : price.toFixed(0);
}

export function formatDate(iso_date) {
    if(iso_date == null) return '';
	 const o_date = LocalDate.parse(iso_date);
	 return o_date.format(DK_DATE_FORMAT);
}

export function formatDateTime(iso_datetime) {
	 // Just in fucking case (of MySQL format): replace space by T.
	 const terrified = iso_datetime.replace(/ /g, "T");
	 const o_dateTime = LocalDateTime.parse(terrified);
	 return o_dateTime.format(DK_DATETIME_FORMAT);
}
