class TokenUtil {
    static session() {
        const tokenString = sessionStorage.getItem('token');
        if(tokenString == null) return null;
        const session = JSON.parse(tokenString);
        return session
    };

    static getToken() {
        const session = TokenUtil.session();
        if(session == null) return null;
        return session?.token
    };

    static getUser() {
        const session = TokenUtil.session();
        if(session == null) return null;
        return session?.user
    };

    static setToken(token) {
        sessionStorage.setItem('token', JSON.stringify(token));
    }

    static removeToken() {
        sessionStorage.removeItem('token');
    }

    static getToken() {
//        const tokenString = sessionStorage.getItem('token');
//        const userToken = JSON.parse(tokenString);
        const session = TokenUtil.session();
        return session?.token;
    }

    static jsonCheckToken(res) {
        if (res.ok) {
            return res.json()
        } else if (res.status == 401) {
            console.log('401 happened')
            TokenUtil.removeToken()
            //Navigate('/login')
            // document.location.href = "https://localhost:3000/login"
            // throw new Error()
        }
    }
}

export default TokenUtil;