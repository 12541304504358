const config = {
   service_url: 'https://gris.rv-holidays.com',
   base_url: 'https://grisling.rv-holidays.com'
// service_url:'http://localhost:8003',
// base_url:'http://localhost:8081'
}

/* 
Facebook app
https://developers.facebook.com/apps/
appid 3620356081416585
secret 8cf3d3cb3b53da4970fb332c5b1f2761
*/

export default config;
