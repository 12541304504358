import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

function ContractItem(props) {
	const initialify = (text) => {
		const pieces = text.split(' ');
		var result = '';
		pieces.forEach(function (item, index) {
			result += item.substring(0, 1);
		});
		return result.substring(0, 3);
	}

	const handleContextMenu = (evt) => {
		if (props.contextMenuHandler) {
			// TODO we need to do something better here... this is a chaos.
			props.contextMenuHandler(props.contract, props.index, evt);
		}
	}

	return (
		<Draggable
			draggableId={props.contract.id.toString()}
			index={props.index}>
			{(provided, snapshot) => (
				<div
					className='contract'
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					onContextMenu={handleContextMenu}
				>
					<span className="w-40 avatar">{initialify(props.contract.name)}</span>
					<div className="contract_name">{props.contract.name}</div>
				</div>
			)}
		</Draggable>
	)
}

function PerPersonTemplateItem(props) {
	return (
		<Draggable
			draggableId='perPersonStepTemplate'
			index={-1}>
			{(provided, snapshot) => (
				<div
					className='contract'
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<span className="w-40 avatar">PP</span>
					<div className="contract_name">Gebyr pr. person</div>
				</div>
			)}
		</Draggable>
	)
}

function FixedStepTemplateItem(props) {
	return (
		<Draggable
			draggableId='fixedPriceStepTemplate'
			index={-2}>
			{(provided, snapshot) => (
				<div
					className='contract'
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<span className="w-40 avatar">M</span>
					<div className="contract_name">Manuelt trin</div>
				</div>
			)}
		</Draggable>
	)
}

function ContractList(props) {
	const contracts = props.contracts
	const groups = []
	const categories = []

	if (contracts && contracts.length) {
		var current_category = contracts[0].category
		var current_group = [];
		///	var index = 0;

		contracts.forEach((contract, index) => {
			if (contract.category !== current_category) {
				groups.push(current_group)
				categories.push(current_category)
				current_group = []
				current_category = contract.category
			}

			current_group.push(
				<ContractItem
					contract={contract}
					key={contract.id}
					index={index}
					contextMenuHandler={props.contextMenuHandler}
				/>)
		})
		groups.push(current_group)
		categories.push(current_category)
	}

	return (
		<Droppable droppableId={props.droppable_id}>
			{(provided, snapshot) => (
				<div className="list-row" id="contracts_list"
					ref={provided.innerRef}
				>
					<FixedStepTemplateItem key='fixedPrice' />
					<PerPersonTemplateItem key='perPerson' />
					{groups && groups.map((group, index) => (
						<div key={index} className='list-group'>
							<div className='group-heading'>
								<h5 className="mb-1" style={{ textDecoration: 'underline' }}>{categories[index]}</h5>
							</div>
							{group}
						</div>
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}

export default ContractList;
