import React from 'react'
import Modal from 'react-bootstrap/Modal'
import TokenUtil from './TokenUtil'

class ContractNotes extends React.Component {
	constructor(props) {
		super(props)
		this.state = { showContractModal: false }
	}

	loadContract(contract_id) {
		const token = TokenUtil.getToken()
		fetch(`${this.props.config.service_url}/ajax/contract.php?&id=${contract_id}&token=${token}`)
			.then(TokenUtil.jsonCheckToken)
			.then(json => {
				this.setState({
					contract: json,
					showContractModal: true
				})
			})
	}

	viewContract(contract_id) {
		this.loadContract(contract_id);
	}

	render() {
		if (!this.state.contract) return ''
		const contract = this.state.contract
		return (
			<Modal size="lg" show={this.state.showContractModal} onHide={e => this.setState({ showContractModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{contract.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{__html: contract.notes}}/>
					<hr/>
					Dolphin beskrivelse:<br/>
					{contract.dolphin_description}
				</Modal.Body>
			</Modal>
		)
	}
}

export default ContractNotes
