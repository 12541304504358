import React from 'react'
import * as Format from './formats.js'

import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from "react-bootstrap/Tooltip"

import TokenUtil from './TokenUtil'

class DiscountTooltip extends React.Component {
	discountName(discount) {
		/*
		switch(discount.type) {
		case 'EarlyBirdUntilDiscount': return 'Early Bird';
		case 'StayPayDiscount': return 'Stay/Pay';
		default: return discount.type;
		}
		*/
		return discount.code
	}

	renderDiscount(discount) {
		switch (discount.type) {
			case 'PercentDiscount': {
				return props => (
					<Tooltip {...props}>{discount.percentDiscount}% rabat</Tooltip>
				)
			}
			case 'EarlyBirdUntilDiscount': {
				const deadline = Format.LocalDate.parse(discount.deadline).format(Format.DK_DATE_FORMAT)
				return props => (
					<Tooltip {...props}>{discount.percentDiscount}% indtil {deadline}</Tooltip>
				)
			}
			case 'EarlyBirdLeadDiscount': {
				const days = discount.leadDays
				return props => (
					<Tooltip {...props}>{discount.percentDiscount}% indtil {days} dage forud</Tooltip>
				)
			}
			case 'StayPayDiscount': {
				// Let's have some fun with stay/pay tables!
				// "stayPayTable": "7:6,12:10,14:12,21:19,"
				const rema = discount.stayPayTable.matchAll(/([0-9]+):([0-9]+)/g)
				var lines = []

				let i = 0;
				for (let staypay of rema) {
					console.log(staypay[1])
					lines.push(<span key={i}>Stay {staypay[1]} pay {staypay[2]}</span>)
					i += 2;
				}
				i = 1;
				while (i <= lines.length) {
					lines.splice(i, 0, <br key={i} />);
					i += 2;
				}

				return props => React.createElement(
					Tooltip,
					props,
					lines
				)
			}
			default: return props => (
				<Tooltip {...props}>Ukendt rabat</Tooltip>
			);
		}
	}

	render() {
		const discount = this.props.discount;
		const renderTooltip = this.renderDiscount(discount);
		return (
			<OverlayTrigger placement="top" overlay={renderTooltip}>
				<span>{this.discountName(discount)}</span>
			</OverlayTrigger>
		)
	}
}

class ContractView extends React.Component {
	constructor(props) {
		super(props)
		this.state = { showContractModal: false }
	}

	loadContract(contract_id) {
		const token = TokenUtil.getToken()
		fetch(`${this.props.config.service_url}/ajax/contract.php?&id=${contract_id}&token=${token}`)
			.then(TokenUtil.jsonCheckToken)
			.then(json => {
				// console.log(result);
				this.setState({
					contract: json,
					showContractModal: true
				})
			})
	}

	viewContract(contract_id) {
		// console.log("Viewing contract: ", contract_id);
		this.loadContract(contract_id);
	}

	render() {
		if (!this.state.contract) return ''
		const contract = this.state.contract
		const variants = contract.variants
		return (
			<Modal size="lg" show={this.state.showContractModal} onHide={e => this.setState({ showContractModal: false })}>
				<Modal.Header closeButton>
					<Modal.Title>{contract.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<Row>
						<Col>
							Id: {contract.id}
						</Col>
						<Col>
							Type: {contract.type}
						</Col>
						<Col>
							Kategori: {contract.category}
						</Col>
					</Row>
					<Row>
						<Col>
							Land: {contract.country}
						</Col>
						<Col>
							Valuta: {contract.ccy}
						</Col>
						<Col />
					</Row>

					<Tabs defaultActiveKey="variant-" id="variants-tab">
						{
							/* This shit is necessary, because: forEach is an Array thing, this is just Object. Even if we made a keys Array,
								forEach is not functional / returns Undefined, and is no use.
								We need transform keys into Array, and then use map, which is functional / returns a new Array.
							*/
							Object.keys(variants).map(key => {
								const variant = variants[key]
								const vkey = 'variant-' + key
								const name = key ? key : 'Basis'
								return (

									<Tab key={vkey} eventKey={vkey} title={name}>
										{/* .table-striped .table-hover */}
										<Table striped>
											<thead>
												<tr>
													<th>Fra</th>
													<th>Til</th>
													<th>Rabatter</th>
													{
														variant.products.map((product, idx) => {
															return (
																<th key={idx}>{product.name}</th>
															)
														})
													}
												</tr>
											</thead>
											<tbody>
												{
													variant.ranges.map((range, idx) => {
														return (
															<tr key={idx}>
																<td>{Format.formatDate(range.valid_from)}</td>
																<td>{Format.formatDate(range.valid_to)}</td>
																<td>
																	{
																		range.discounts.map((discount, idx) =>
																			<>
																				{idx ? <br /> : ''}
																				<DiscountTooltip key={idx} discount={discount} />
																			</>)
																	}
																</td>
																{
																	variant.products.map((product, idx) =>
																		<td key={idx}>{range.productMap[product.name]}</td>
																	)
																}
															</tr>
														)
													}
													)}
											</tbody>
										</Table>
									</Tab>)
							})
						}
					</Tabs>
				</Modal.Body>
			</Modal>
		)
	}
}

export default ContractView
