import React, { useState, useRef } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import 'bootstrap/dist/css/bootstrap.min.css';
import './grisling.css';

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Table from 'react-bootstrap/Table'
import TokenUtil from './TokenUtil'

import * as Formats from './formats'
import ContractList from './contractList'
import ContractView from './contractView'
import ContractNotes from './contractNotes'
import StepsList, { TotalsRow } from './stepsList'

const { v4: uuidv4 } = require('uuid');

/**
 * Reorder steps of composed trip.
 */
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
}

/**
 * Make contract into step
 */
const addStep = (source, destination, droppableSource, droppableDestination) => {
    const contract = source[droppableSource.index];
    
    const step = {
	//is_duration_sensitive: contract && contract.is_duration_sensitive,
	    id: uuidv4()
	//	    num_days: contract.num_days
    }
    
    if(contract) step.num_days = contract.num_days
    
	if (contract && contract.id != null) {
		step.contract = contract
		//		    step.contract_id = contract.id
		step.type = 'ContractStep'

		// Fuck, we need the variants. Right now, from a different API call.
		/*
			fetch(`${this.props.config.service_url}/ajax/countries.php`)
					.then(res => res.json())
					.then(result => {
						  this.setState({
								countryList: result
						  });
					})
	  }
		*/

	} else {
		if (droppableSource.index === -2) {
			step.type = 'FixedPriceStep'
		} else
			step.type = 'PerPersonTemplateStep';

		// console.log(source, droppableSource)
		step.name = ''
	}

	// Add new item, with random id
	destination.splice(droppableDestination.index, 0, step)

	const result = {}

	result[droppableSource.droppableId] = source
	result[droppableDestination.droppableId] = destination

	return result
}

const removeStep = (steps, index) => {
	steps.splice(index, 1)
	return steps
}

/*
  const grid = 8;
  const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'grey',

  // styles we need to apply on draggables
  ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250
  });
*/

function ContractContextMenu(props) {
	return (
		<div className="dropdown-menu dropdown-menu-sm" id="contract-context-menu">
			<a className="dropdown-item" href="#" onClick={() => props.tripEditor.handleContractContextMenuSelection('view_contract')}>Vis kontrakt</a>
			<a className="dropdown-item" href="#" onClick={() => props.tripEditor.handleContractContextMenuSelection('view_contract_notes')}>Vis kontraktnoter</a>
		</div>
	);

}

function StepContextMenu(props) {
	return (
		<div className="dropdown-menu dropdown-menu-sm" id="step-context-menu">
			<a className="dropdown-item" id="step-context-menu-contract-item" href="#" onClick={() => props.tripEditor.handleStepContextMenuSelection('view_contract')}>Vis kontrakt</a>
			<a className="dropdown-item" id="step-context-menu-contract-item" href="#" onClick={() => props.tripEditor.handleStepContextMenuSelection('view_contract_notes')}>Vis kontraktnoter</a>
			<a className="dropdown-item" href="#" onClick={() => props.tripEditor.handleStepContextMenuSelection('delete')}>Slet</a>
			{ /*<a className="dropdown-item" href="#" onClick={()=>props.tripEditor.handleStepContextMenuSelection('c')}>Something else here</a> */}
		</div>
	);
}

function SearchTripResults(props) {
	const trips = props.trips;
	const selectionHandler = e => {
		const row = e.target.closest('tr')
		const trip_id = row.getAttribute('data-trip_id')
		props.handleTripSelected(trip_id)
	}

	const deletionHandler = e => {
		const row = e.target.closest('tr')
		const trip_id = row.getAttribute('data-trip_id')
		const trip_description = row.getAttribute('data-trip_description')
		props.parent.setState({ deleteTripModalText: trip_description, deleteTripId: trip_id, showDeleteTripModal: true })
	}

	return (
		<Table striped hover>
			<thead>
				<tr>
					<th>Id</th>
					<th>Kunde</th>
					<th>Rejse</th>
					<th>Dato</th>
					<th>Version</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{
					trips && trips.map((trip, idx) => {
						return (
							<tr data-trip_id={trip.id} data-trip_description={trip.customer_name + ': ' + trip.name + ', ' + trip.version_date} key={idx}>
								<td>{trip.id}</td>
								<td>{trip.customer_name}<br />{trip.customer_email}</td>
								<td>{trip.name}</td>
								<td>{Formats.formatDateTime(trip.version_date)}</td>
								<td>{trip.version_notes}</td>
								<td>
									<a href='#' onClick={selectionHandler}>Åbn</a><br />
									<a href='#' onClick={deletionHandler}>Slet</a>
								</td>
							</tr>
						)
					})
				}
			</tbody>
		</Table>
	);
}

function SearchCMSTripResults(props) {
	const trips = props.trips;
	const selectionHandler = e => {
		const row = e.target.closest('tr')
		const trip_id = row.getAttribute('data-trip_id')
		props.handleTripSelected(trip_id)
	}

	return (
		<Table striped hover>
			<thead>
				<tr>
					<th>Navn</th>
					<th>Varighed</th>
				</tr>
			</thead>
			<tbody>
				{
					trips && trips.map((trip, idx) => {
						return (
							<tr data-trip_id={trip.id} key={idx} onClick={selectionHandler}>
								<td>{trip.name}</td>
								<td>{trip.duration} dage</td>
							</tr>
						)
					})
				}
			</tbody>
		</Table>
	);
}

function DolphinExportView(props) {
	const trip = props.trip
	const target = useRef(null)

	return (<>
		<label><input type="number"></input> Dolphin folded ID</label>
	</>);
}

function TripSummaryView(props) {
	const formatVariant = variant => variant ? variant : "Basis"
	const trip = props.trip
	return (
		<>
			Rejse: {trip.name}
			<br />
			Kunde: {trip.customer_name}
			<br />
			Email: {trip.customer_email}
			<br />
			Tilbudsdato: {trip.version_date}
			<br />
			Gruppe: {trip.party.adults} voksne, {trip.party.children} børn
			<br />
			Tilbud gyldigt indtil: {trip.bookingDate}
			<br />
			Afrejsedato: {trip.departureDate}
			<br />
			Valuta: {trip.currency}
			<Table hover>
				<thead>
					<tr>
						<th>Navn</th>
						<th>Variant</th>
						<th>Fra</th>
						<th>Antal</th>
						<th>Pris</th>
					</tr>
				</thead>
				<tbody>
					{
						trip.steps.map((step, index) => {
							return (
								<tr key={index}>
									<td>{step.itemName}</td>
									<td>{formatVariant(step.variantName)}</td>
									<td>{step.firstDate ? Formats.formatDate(step.firstDate) : ''}</td>
									<td>{step.numDays ? step.numDays : ''}</td>
									<td>{Formats.formatPrice(step.salePriceCcy)}</td>
								</tr>
							)
						})
					}
				</tbody>
			</Table>
			I alt: {trip.currency} {Formats.formatPrice(trip.salePriceCcy)}, uden rabat {trip.currency} {Formats.formatPrice(trip.undiscountedSalePriceCcy)}
		</>
	)
}

class TripEditor extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			contracts: [],
			search_customer_name: '',
			search_customer_email: '',
			search_trip_name: '',
			search_cms_trip_name: '',
			search_cms_trip_country: 'TH',
			currency: 'DKK',
			// pax_list: [], // does that belong here?
			... this.templateTrip()
		}
	}

	stupidClickHandler() {
		let elems = document.getElementsByClassName('dropdown-menu')
		for (let elem of elems) {
			elem.classList.remove('show')
		}
	}

	handleEnterKeyTrigger(evt, triggerFunc) {
		if (evt.key === "Enter") {
			evt.preventDefault()
			triggerFunc()
		}
	}	

	componentDidMount() {
		// general inputs
		this.handleAnyTripInput = this.handleAnyTripInput.bind(this)
		this.handleAnyOtherInput = this.handleAnyOtherInput.bind(this)
		this.handleAnyStepInput = this.handleAnyStepInput.bind(this)

		this.handleContractContextMenu = this.handleContractContextMenu.bind(this)
		this.handleVariantSelection = this.handleVariantSelection.bind(this)
		this.handleStepContextMenu = this.handleStepContextMenu.bind(this)

		this.handleSearchTrip = this.handleSearchTrip.bind(this)
		this.handleEnterKeyTrigger = this.handleEnterKeyTrigger.bind(this)
		this.handleSearchCMSTrip = this.handleSearchCMSTrip.bind(this)

		this.handleDoSaveButton = this.handleDoSaveButton.bind(this)
		this.handleOverwriteOrSaveNewTripButton = this.handleOverwriteOrSaveNewTripButton.bind(this)

		this.onDragEnd = this.onDragEnd.bind(this)

		let elem = document.getElementById('root')
		elem.onclick = this.stupidClickHandler
		this.populateCountryList()
		this.searchContracts('', '') // TODO this is inefficient.
	}

	handleAnyTripInput(evt) {
		const target = evt.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			[name]: value,
			calculation_valid: false,
			has_unsaved_changes: true
		})
	}

	handleAnyOtherInput(evt) {
		const target = evt.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		this.setState({
			[name]: value,
		})
	}

	handleAnyStepInput(idx, evt) {
		const target = evt.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		const steps = this.state.steps
		steps[idx][name] = value
		
		this.setState(
			{
				steps: steps,
				calculation_valid: false,
				has_unsaved_changes: true
			}
		)
	}

	populateCountryList() {
		fetch(`${this.props.config.service_url}/ajax/countries.php`)
			.then(res => res.json())
			.then(result => {
				this.setState({
					countryList: result
				});
			})
	}

	searchContracts(search_term, country) {
		search_term = search_term ? search_term : ''
		country = country ? country : ''
		const token = TokenUtil.getToken()
		fetch(`${this.props.config.service_url}/ajax/search_contracts.php?country=${country}&name_term=${search_term}&token=${token}`)
			.then(TokenUtil.jsonCheckToken)
			.then(result => {
				console.log('setState sc')
				this.setState({
					contracts: result,
					contract_search_term: search_term,
					contract_country: country
				});
				//					 this.props.onContractsLoaded(result);
				// if we have to, we can set a loaded flag. But we are torn down and rerendered in parent component anyway.
			})
	}

	handleContractSearchTerm(evt) {
		const val = evt.target.value;
		this.searchContracts(val, this.state.contract_country);
	}

	handleContractCountry(evt) {
		const country = evt.target.value;
		this.searchContracts(this.state.contract_search_term, country);
	}

	handleShowCalculateModal(evt) {
		this.setState({ showCalculateModal: true });
	}

	serializeTrip() {
		const data = {
			num_adults: this.state.num_adults,
			num_children: this.state.num_children,
			booking_date: this.state.booking_date,
			departure_date: this.state.departure_date,
			currency: this.state.currency,
			margin: this.state.margin,
		    steps: this.state.steps
		};
		return data;
	}

	submitCalculateTrip() {
		const data = this.serializeTrip()
		data.currency = this.state.currency
		const token = TokenUtil.getToken()
		const prom = fetch(
			this.props.config.service_url + `/ajax/calculateTrip_json.php?token=${token}`,
			{
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data) // body data type must match "Content-Type" header
			}
		)
		return prom
	}

	calculateTrip(and_then = null) {
		const prom = this.submitCalculateTrip()
		prom.then(TokenUtil.jsonCheckToken)
			.then(
				data => {
					if (data.status === 'OK') {
						// we reuse the steps from local state, assuming their indices have not changed...
						// They are in the state of TripEditor, not StepsList.
						const steps = this.state.steps;
						data.result.steps.forEach((step, idx) => {
							// TODO - could use some smarter property copying. And naming...
							steps[idx].firstDate = step.firstDate
							steps[idx].lastDate = step.lastDate
							steps[idx].salePriceCcy = step.salePriceCcy
							steps[idx].marginCcy = step.marginCcy
							steps[idx].currency = step.currency
							steps[idx].undiscountedSalePriceCcy = step.undiscountedSalePriceCcy
							steps[idx].saleCost = step.cost
							steps[idx].undiscountedSaleCost = step.undiscountedCost
							steps[idx].productNames = step.productNames
							steps[idx].discountCodes = step.discountCodes
							}
						)
						const totals = {
							currency: data.result.currency,
							salePriceCcy: data.result.salePriceCcy,
							undiscountedSalePriceCcy: data.result.undiscountedSalePriceCcy,
							marginCcy: data.result.marginCcy,
							perPersonUndiscountedSalePriceCcy: data.result.perPersonUndiscountedSalePriceCcy,
							perPersonSalePriceCcy: data.result.perPersonSalePriceCcy,
							isValid: !data.result.hasMissingRates,
							costByCurrencies: data.result.costByCurrencies
						}

						this.setState(
							{
								currency: data.result.currency,
								totals,
								steps,
								calculation_valid: true
							}
						);

						if (and_then) {
							and_then()
						}
					} else {
						this.setState({
							showCalculationErrorModal: true,
							calculationErrorText: data.message
						})
					}
				}
			).catch(error => {
				// console.log("reject1: ", error)
			})
	}


	handleCalculateTrip(evt) {
		this.calculateTrip()
	}

	tripSummary(and_then = null) {
		const prom = this.submitCalculateTrip()
		prom.then(
			TokenUtil.jsonCheckToken).then(
				data => {
					if (data.status === 'OK') {
						this.state.tripSummary = data.result
						if (and_then)
							and_then()
					} else {
						alert(data.message)
					}
				})
	}

	handleTripSummary(evt) {
		this.tripSummary(() => this.setState({ showSummaryModal: true }))
	}

	dolphinExport(and_then = null) {
		const prom = this.submitCalculateTrip()
		prom.then(
			TokenUtil.jsonCheckToken).then(
				data => {
					if (data.status === 'OK') {
						this.state.tripSummary = data.result
						if (and_then)
							and_then()
					} else {
						alert(data.message)
					}
				})
	}

	handleDolphinExport(evt) {
		this.dolphinExport(() => this.setState({ showDolphinModal: true }))
	}

	loadTrip(id, andThen = null) {
		const token = TokenUtil.getToken()
		const prom = fetch(
			this.props.config.service_url + `/ajax/individual_trip.php?id=${id}&token=${token}`,
			{
				method: 'GET',
				cache: 'no-cache',
			});
		prom.then(TokenUtil.jsonCheckToken)
			.then(
				trip => {
					trip.steps.forEach((step, idx) => {
						step.id = uuidv4() // This is NOT related to db-id of the step. Just a key for the draggable framework.
					})
					this.setState({
						trip_id: trip.id,
						steps: trip.steps,
						num_adults: trip.party.adults,
						num_children: trip.party.children,
						margin: trip.margin,
						calculation_valid: false,
						has_unsaved_changes: false,
						booking_date: trip.booking_date,
						departure_date: trip.departure_date,

						trip_name: trip.name,
						dolphin_folder_id: trip.dolphin_folder_id,
						customer_name: trip.customer_name,
						customer_email: trip.customer_email,
						version_date: trip.version_date, // not really needed no more
						version_notes: trip.version_notes,
					})
					if (andThen)
						andThen()
				}
			)
	}

	loadCMSTrip(id, andThen = null) {
		const token = TokenUtil.getToken()
		const prom = fetch(
			this.props.config.service_url + `/ajax/individual_trip_from_cms.php?id=${id}&num_adults=${this.state.num_adults}&num_children=${this.state.num_children}&booking_date=${this.state.booking_date}&departure_date=${this.state.departure_date}&token=${token}`,
			{
				method: 'GET',
				cache: 'no-cache',
			});

		prom.then(TokenUtil.jsonCheckToken)
			.then(
				trip => {
					trip.steps.forEach((step, idx) => {
						step.id = uuidv4() // This is NOT related to db-id of the step. Just a key for the draggable framework.
					})
					
					this.setState({
						steps: trip.steps,
						calculation_valid: false,
						has_unsaved_changes: false,
						trip_name: trip.name,
						margin: trip.margin
					})
					if (andThen)
						andThen()
				}
			)
	}

	handleSearchTripSelected(id) {
		const action = () => this.loadTrip(id, () => this.setState({ showSearchTripModal: false, showClearTripModal: false }))
		if (this.state.has_unsaved_changes) {
			
			this.setState({
				showClearTripModal: true,
				clearTripModalText: "Vil du slette dine ændringer, og i stedet åbne rejsen?",
				clearTripModalAction: action
			})
		} else {
			action()
		}
	}

	handleSearchCMSTripSelected(id) {
		const action = () => this.loadCMSTrip(id, () => this.setState({ showSearchCMSTripModal: false, showClearTripModal: false }))
		if (this.state.has_unsaved_changes) {
			this.setState({
				showClearTripModal: true,
				clearTripModalText: "Vil du slette dine ændringer, og i stedet åbne webrejsen?",
				clearTripModalAction: action
			})
		} else {
			action()
		}
	}

	areTripInfoFilled(alsoDolphin) {
		const result = this.state.trip_name !== null && this.state.trip_name !== '' &&
			this.state.customer_name !== null && this.state.customer_name !== '' &&
			this.state.customer_email !== null && this.state.customer_email !== '' &&
			(!alsoDolphin || (this.state.dolphin_folder_id > 0))
		return result
	}

	saveTrip(exportToDolphin = false, and_then = null) {
		const data = this.serializeTrip()
		data.trip_id = this.state.trip_id //overwrite ? this.state.trip_id : null
		data.customer_name = this.state.customer_name
		data.customer_email = this.state.customer_email
		data.name = this.state.trip_name
		data.version_notes = this.state.version_notes
		data.dolphin_folder_id = this.state.dolphin_folder_id
		if(exportToDolphin) data.export_to_dolphin = true
		const token = TokenUtil.getToken()

		const prom = fetch(
			this.props.config.service_url + `/ajax/save_individual_trip_json.php?token=${token}`,
			{
				method: 'POST',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(data)
			});

		prom
			.then(TokenUtil.jsonCheckToken).then(
				data => {
					this.setState(
						{
							trip_id: data.trip_id,
							has_unsaved_changes: false
						});
					if (and_then) {
						and_then()
					}
				})
	}

	handleOverwriteOrSaveNewTripButton(exportToDolphin) {
		//let exportToDolphin = this.state.exportToDolphin
		console.log("Export to dolphin " + exportToDolphin)
		this.saveTrip(
			exportToDolphin,
			() => {
				this.setState({ showOverwriteTripModal: false })
				this.setState({ showSaveExportModal: false })
			})
	}

	handleDoSaveButton(exportToDolphin = false) {
		// this.setState({ exportToDolphin: andExport })
		if (this.areTripInfoFilled(exportToDolphin)) {
			document.getElementById('fill_all_save_fields_warning').style.visibility = 'hidden'
			//if (this.state.trip_id == null) {
			this.handleOverwriteOrSaveNewTripButton(exportToDolphin)
			//} else {
			//	this.setState({ showOverwriteTripModal: true })
			//}
		} else {
			document.getElementById('fill_all_save_fields_warning').style.visibility = 'visible'
		}
	}

	handleSearchTrip() {
		const token = TokenUtil.getToken()
		fetch(`${this.props.config.service_url}/ajax/search_individual_trips.php?customer_name_term=${this.state.search_customer_name}&customer_email_term=${this.state.search_customer_email}&trip_name_term=${this.state.search_trip_name}&token=${token}`)
			.then(TokenUtil.jsonCheckToken)
			.then(result => {
				this.setState({
					searchedTrips: result
				})
			})
	}

	handleSearchCMSTrip() {
		const token = TokenUtil.getToken()
		const country = this.state.search_cms_trip_country
		const term = this.state.search_cms_trip_name
		fetch(`${this.props.config.service_url}/ajax/search_cms_trips.php?name_term=${term}&country=${country}&token=${token}`)
			.then(res => res.json())
			.then(result => {
				this.setState({
					searchedCMSTrips: result
				})
			})
	}

	handleContractContextMenu(contract, index, evt) {
		var top = evt.pageY - 10
		var left = evt.pageX - 90

		let elem = document.getElementById('contract-context-menu')

		elem.style.top = top + 'px'
		elem.style.left = left + 'px'
		elem.classList.add('show')

		this.setState(
			{
				contextContract: contract,
				// contextContractIndex: index   but nobody uses it.
			}
		)

		evt.preventDefault()
	}

	handleVariantSelection(step, variant) {
		// console.log(`Setting variant ${variant} for step`)
		step.variant = variant
		this.setState(
			{
				calculation_valid: false
			}
		);
	}

	handleStepContextMenu(step, index, evt) {
		var top = evt.pageY - 10
		var left = evt.pageX - 90

		let elem = document.getElementById('step-context-menu')
		let cselem = document.getElementById('step-context-menu-contract-item')

		if (step.type == 'ContractStep')
			cselem.style.display = 'block'
		else
			cselem.style.display = 'none'

		elem.style.top = top + 'px'
		elem.style.left = left + 'px'
		elem.classList.add('show')

		this.setState(
			{
				contextStep: step,
				contextStepIndex: index
			}
		)

		evt.preventDefault()
	}

	handleContractContextMenuSelection(action) {
		// const component = this.state.contextStepComponent;
		switch (action) {
			case 'view_contract':
				this.contractView.viewContract(this.state.contextContract.id);
				break;
			case 'view_contract_notes':
				this.contractNotes.viewContract(this.state.contextContract.id);
				break;
			default: break;
		}

		let elem = document.getElementById('step-context-menu');
		elem.classList.remove('show');
	}

	handleStepContextMenuSelection(action) {
		switch (action) {
			case 'view_contract':
				if (this.state.contextStep.contract)
					this.contractView.viewContract(this.state.contextStep.contract.id);
				break;
			case 'view_contract_notes':
				if (this.state.contextStep.contract)
					this.contractNotes.viewContract(this.state.contextStep.contract.id);
				break;
			case 'delete':
				const index = this.state.contextStepIndex;
				const steps = removeStep(
					this.state.steps,
					// Uh-oh we have a problem here. component.props.index
					index
				);
				this.setState({
					steps,
					calculation_valid: false,
					has_unsaved_changes: true
				});
				break;
			default: break;
		}
		let elem = document.getElementById('step-context-menu');
		elem.classList.remove('show');
	}

	onDragEnd(result) {
		const { source, destination } = result;
		if (source && source.droppableId === 'steps_droppable'
			&& destination && destination.droppableId === 'steps_droppable') {

			// Step reordering.
			const steps = reorder(
				//this.getList(source.droppableId),
				this.state.steps,
				source.index,
				destination.index
			);
			this.setState({
				steps,
				calculation_valid: false,
				has_unsaved_changes: true
			});
		} else if (source && source.droppableId === 'steps_droppable') {
			// Drag-out, ie. remove.
			const steps = removeStep(
				this.state.steps,
				source.index
			);
			this.setState({
				steps,
				calculation_valid: false,
				has_unsaved_changes: true
			});
		} else if (source && source.droppableId === 'contracts_droppable'
			&& destination && destination.droppableId === 'steps_droppable') {
			// Add step				
			const result = addStep(
				this.state.contracts,
				this.state.steps,
				source,
				destination
			);

			this.setState({
				steps: result.steps_droppable,
				calculation_valid: false,
				has_unsaved_changes: true
			});
		}
	};

	templateTrip() {
		const token = TokenUtil.getToken()
		const prom = fetch(
			this.props.config.service_url + `/ajax/template_trip.php?token=${token}`,
			{
				method: 'GET',
				cache: 'default',
			});

		// this.setState ({
		//     ...this.blankTrip()
		//	})

		prom.then((response) => response.json().then(
			trip => {
				trip.steps.forEach((step, idx) => {
					step.id = uuidv4() // This is NOT related to db-id of the step. Just a key for the draggable framework.
				})
				this.setState({
					steps: trip.steps,
					...this.blankTrip()
				})
			}
		))
	}

	blankTrip() {
		return {
			num_adults: 2,
			num_children: 0,

			calculation_valid: false,
			has_unsaved_changes: false,

			booking_date: Formats.LocalDate.now().format(Formats.ISO_DATE_FORMAT),
			departure_date: Formats.LocalDate.now().plusMonths(3).format(Formats.ISO_DATE_FORMAT),
			trip_id: null,
			trip_name: '',
			dolphin_folder_id: '',
			customer_email: '',
			customer_name: '',
			version_date: '',
			version_notes: '',
			margin: 20
		}
	}

	clearTrip() {
		const nextState = {
			showClearTripModal: false,
			steps: [],
			...this.blankTrip()
		}
		this.setState(nextState)
	}

	handleClearTripButton() {
		if (this.state.has_unsaved_changes) {
			this.setState({
				showClearTripModal: true,
				clearTripModalText: "Vil du slette dine ændringer, og lave et nyt tilbud?",
				clearTripModalAction: () => { this.setState({ showClearTripModal: false }); this.templateTrip() }
			})
		} else {
			// this.clearTrip()
			this.templateTrip();
		}
	}

	uniqueCurrencies() {
		if (!this.state.countryList) return []
		const result = []
		this.state.countryList.forEach(country => {
			const currency = country.currency
			if (!result.includes(currency)) result.push(currency)
		})
		result.sort()
		return result
	}

    handleOrderingEmails() {
	const data = this.serializeTrip()
	data.currency = this.state.currency
	data.customer_name = this.state.customer_name
	data.customer_email = this.state.customer_email
	let token = Math.random()
	const prom = fetch(
	    this.props.config.service_url + `/ajax/orderingEmails.php?token=${token}`,
	    {
		method: 'POST',
		cache: 'no-cache',
		headers: {
		    'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	    }
	)

	prom.then(response => response.text()).then(text => {
	    this.setState({ orderingMails: text })
		this.setState({ showOrderingModal: true })
	})
    }

	render() {
		const currencies = this.uniqueCurrencies()
		return (
			<>
				<div id="top_bar">
					<div className='button_bar'>
						<div className='app_title'>Grisling</div> &nbsp;
						<Button variant='secondary' size='sm' onClick={() => this.handleClearTripButton()}>Nyt tilbud</Button>
			<Button variant='secondary' size='sm' onClick={() => this.setState({ showSearchTripModal: true })}>Åbn tilbud&#x2026;</Button>
			<Button variant='secondary' size='sm' onClick={() => this.setState({ showSearchCMSTripModal: true })}>Nyt tilbud fra webrejse&#x2026;</Button>
			<Button variant='secondary' size='sm' onClick={evt=>this.handleOrderingEmails(evt)}>Bestillingsmails</Button>
					</div>
					<div className='button_bar login_bar'>
						{ /* Sigurd S&aelig;lger
		            <Button variant='warning' size='sm'>Log ud</Button>
                  */ }
					</div>
				</div>

				<DragDropContext onDragEnd={this.onDragEnd}>
					<div id="left_column">
						<span className='headline'>Kontrakter</span>
						<div style={{ marginTop: '10px' }}>
							<select onChange={e => this.handleContractCountry(e)}><option value=''>Land</option>
								{
									this.state.countryList &&
									this.state.countryList.map(
										(country, i) =>
											<option key={country.code} value={country.code}>{country.name}</option>
									)
								}
							</select>
							<input placeholder="S&oslash;g" onChange={e => this.handleContractSearchTerm(e)} />
						</div>

						<ContractList
							droppable_id="contracts_droppable"
							contracts={this.state.contracts}
							contextMenuHandler={this.handleContractContextMenu}
						/>
					</div>

					<div id="right_column">

						<div id='right_head_row' className="grisling_padded">
							<span className='headline'>Tilbud</span>
							<div style={{ display: 'inline-block', float: 'right' }}>
								<Button size='sm' onClick={evt => this.handleCalculateTrip(evt)}>Beregn</Button>
								<Button size='sm' onClick={e => this.handleTripSummary(e)}>Prisopstilling&#x2026;</Button>
								<Button size='sm' onClick={() => this.setState({ showSaveExportModal: true })}>Gem og eksportér&#x2026;</Button>
							</div>
						</div>

						<div id='right_inputs_row' className='grisling_padded'>
							<label>Indtil
								<input type='date' className='date_input' name='booking_date' value={this.state.booking_date ?? ''} onChange={this.handleAnyTripInput} />
							</label>
							<label>Afrejse
								<input type='date' className='date_input' name='departure_date' value={this.state.departure_date ?? ''} onChange={this.handleAnyTripInput} />
							</label>
							<label>
								voksne
								<input type='number' className='numpersons' name='num_adults' value={this.state.num_adults ?? 2} onChange={this.handleAnyTripInput} />
							</label>
							<label>
								børn
								<input type='number' className='numpersons' name='num_children' value={this.state.num_children ?? 0} onChange={this.handleAnyTripInput} />
							</label>
							<label>Valuta
								<select name="currency" value={this.state.currency ?? ''} onChange={this.handleAnyTripInput}>
									{
										currencies.map(
											(ccy, i) =>
												<option key={ccy} value={ccy}>{ccy}</option>
										)
									}
								</select>
							</label>
							<label>Dækn.grad
								<input type='number' className='margin_input' name='margin' step='any' value={this.state.margin ?? 20} onChange={this.handleAnyTripInput} />
							</label>
						</div>
						{ /* Maybe the steps_list should be this??? */}
						<div id='right_contents_row'>
							<div className='row' style={{ paddingLeft: '15px', paddingRight: '15px' }}>
								<div className='col-sm-1'></div>
								<div className='col-sm-3'>Kontrakt</div>
								<div className='col-sm-3'>Dag</div>
								<div className='col-sm-2'>Dato/Produkt</div>
								<div className='col-sm-1 price'>Netto</div>
								<div className='col-sm-1 price'>DB</div>
								<div className='col-sm-1 price'>Brutto</div>
							</div>
							<StepsList
								droppable_id="steps_droppable"
								steps={this.state.steps}
								handleAnyStepInput={this.handleAnyStepInput}
								currencyList={this.uniqueCurrencies()}
								calculation_valid={this.state.calculation_valid}
								currency={this.state.currency}
								totals={this.state.totals}
								contextMenuHandler={this.handleStepContextMenu}
								variantSelectionHandler={this.handleVariantSelection}
								num_adults={this.state.num_adults}
								num_children={this.state.num_children}
							/>

						</div>
						<TotalsRow totals={this.state.totals} index='1000' calculation_valid={this.state.calculation_valid} />
					</div>
				</DragDropContext>

				{/*This is a weird kind of thing to do... maybe we find better */}
				<ContractContextMenu tripEditor={this} />
				<StepContextMenu tripEditor={this} />

				<ContractView config={this.props.config} ref={(c) => this.contractView = c} />
				<ContractNotes config={this.props.config} ref={(c) => this.contractNotes = c} />

				<Modal size='sm' show={this.state.showOverwriteTripModal} onHide={() => this.setState({ showOverwriteTripModal: false })}>
					<Modal.Header>
						<Modal.Title>Overskriv rejse?</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Vil du overskrive den gemte rejse?
						<br />
						<Button variant="warning" onClick={() => this.handleOverwriteOrSaveNewTripButton(true)}>Overskriv</Button>
						<Button variant="success" onClick={() => this.handleOverwriteOrSaveNewTripButton(false)}>Gem ny</Button>
					</Modal.Body>
				</Modal>

				<Modal size='sm' show={this.state.showClearTripModal} onHide={() => this.setState({ showClearTripModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Slet data</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.clearTripModalText}
						<br />
						<Button variant="success" onClick={() => this.state.clearTripModalAction()}>OK</Button>
						<Button variant="danger" onClick={() => this.setState({ showClearTripModal: false })}>Nej</Button>
					</Modal.Body>
				</Modal>

				<Modal size='md' show={this.state.showCalculationErrorModal} onHide={() => this.setState({ showCalculationErrorModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Fejl</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div dangerouslySetInnerHTML={{ __html: this.state.calculationErrorText }} />
						<br />
						<Button variant="primary" onClick={() => this.setState({ showCalculationErrorModal: false })}>Føj!!</Button>
					</Modal.Body>
				</Modal>

				<Modal size='lg' show={this.state.showSearchTripModal} onHide={() => this.setState({ showSearchTripModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Søg tilbud</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{ /*
					          <select><option value=''>Land</option>
					          {
						        this.state.countryList &&
								    this.state.countryList.map(
									  (country, i) =>
										<option key={country.code} value={country.code}>{country.name}</option>
								    )
					          }
						        </select>
						      */}
						<input placeholder="Kundens navn" name="search_customer_name" value={this.state.search_customer_name} onKeyDown={e=>this.handleEnterKeyTrigger(e, this.handleSearchTrip)} onChange={this.handleAnyOtherInput} />
						<input placeholder="Kundens email" name="search_customer_email" value={this.state.search_customer_email} onKeyDown={e=>this.handleEnterKeyTrigger(e, this.handleSearchTrip)} onChange={this.handleAnyOtherInput} />
						<input placeholder="Rejsens navn" name="search_trip_name" value={this.state.search_trip_name} onKeyDown={e=>this.handleEnterKeyTrigger(e, this.handleSearchTrip)} onChange={this.handleAnyOtherInput} />
						<div style={{ display: 'inline-block', float: 'right' }}>
							<Button variant="primary" onClick={this.handleSearchTrip}>Søg</Button>
						</div>

						{/*<input placeholder="Versionsnoter" name="search_version_notes" value={this.state.search_version_notes} onChange={this.handleAnyOtherInput} />*/}

						<SearchTripResults parent={this} trips={this.state.searchedTrips} handleTripSelected={id => this.handleSearchTripSelected(id)} />

					</Modal.Body>
				</Modal>

				<Modal size='lg' show={this.state.showSearchCMSTripModal} onHide={() => this.setState({ showSearchCMSTripModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Søg webrejse</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{
							<select 
								name='search_cms_trip_country' 
								value={this.state.search_cms_trip_country} 
								onChange={this.handleAnyOtherInput} >
								{
									this.state.countryList &&
									this.state.countryList.map(
										(country, i) =>
											<option key={country.code} value={country.code}>{country.name}</option>
									)
								}
							</select>
						}
						<input 
							placeholder="Rejsens navn" 
							name="search_cms_trip_name" 
							value={this.state.search_cms_trip_name} 
							onChange={this.handleAnyOtherInput} 
							onKeyDown={e=>this.handleEnterKeyTrigger(e, this.handleSearchCMSTrip)}
							/>
						<div style={{ display: 'inline-block', float: 'right' }}>
							<Button variant="primary" onClick={this.handleSearchCMSTrip}>Søg</Button>
						</div>

						<SearchCMSTripResults trips={this.state.searchedCMSTrips} handleTripSelected={id => this.handleSearchCMSTripSelected(id)} />
					</Modal.Body>
				</Modal>

				<Modal show={this.state.showSaveExportModal} onHide={e => this.setState({ showSaveExportModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Gem og eksportér</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						* <input placeholder="Kundens navn" style={{ width: '95%' }} name="customer_name" value={this.state.customer_name} onChange={this.handleAnyOtherInput} />
						<br />
						* <input placeholder="Kundeemail" style={{ width: '95%' }} type="email" name="customer_email" value={this.state.customer_email} onChange={this.handleAnyOtherInput} />
						<br />
						* <input placeholder="Rejsens navn" style={{ width: '95%' }} name="trip_name" value={this.state.trip_name} onChange={this.handleAnyTripInput} />
						<br />
						&nbsp;&nbsp; <input placeholder="Dolphin folder id" style={{ width: '95%' }} name="dolphin_folder_id" value={this.state.dolphin_folder_id} onChange={this.handleAnyOtherInput} />
						<br />
						&nbsp;&nbsp; <input placeholder="Versionsnoter" style={{ width: '95%' }} name="version_notes" value={this.state.version_notes} onChange={this.handleAnyOtherInput} />
						<br />
						&nbsp;&nbsp; <span style={{ visibility: 'hidden', color: 'red' }} id='fill_all_save_fields_warning'>Udfyld venligst alle felter med *. Til Dolphin export skal en gyldig Dolphin Folder id være udfyldt.</span>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="success" onClick={(e) => this.handleDoSaveButton(false)}>Gem</Button>
						<Button variant="success" onClick={(e) => this.handleDoSaveButton(true)}>Gem og send til Dolphin</Button>
					</Modal.Footer>
				</Modal>

				<Modal size='lg' show={this.state.showSummaryModal} onHide={() => this.setState({ showSummaryModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Tilbud</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<TripSummaryView trip={this.state.tripSummary} />
					</Modal.Body>
				</Modal>

		    	<Modal size='lg' show={this.state.showOrderingModal} onHide={() => this.setState({ showOrderingModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Bestillingsmails</Modal.Title>
					</Modal.Header>
			<Modal.Body>
			<div dangerouslySetInnerHTML={{__html: this.state.orderingMails }}></div>
					</Modal.Body>
				</Modal>

				<Modal size='lg' show={this.state.showDolphinModal} onHide={() => this.setState({ showDolphinModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Dolphin eksport</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<DolphinExportView />
					</Modal.Body>
				</Modal>

				<Modal size='sm' show={this.state.showDeleteTripModal} onHide={() => this.setState({ showDeleteTripModal: false })}>
					<Modal.Header closeButton>
						<Modal.Title>Slet tilbud</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{this.state.deleteTripModalText}
						<br />
						<Button variant="danger" onClick={(e) => {
							const data = new FormData()
							const token = TokenUtil.getToken()
							data.append('trip_id', + this.state.deleteTripId)
							fetch(
								this.props.config.service_url + `/ajax/delete_individual_trip.php?token=${token}`,
								{
									method: 'POST',
									body: data,
								}).then(data => { this.setState({ showDeleteTripModal: false }); this.handleSearchTrip() })
						}}>Slet</Button>
					</Modal.Body>
				</Modal>

			</>
		);
	}
}

// Put the things into the DOM!
// ReactDOM.render(<App />, document.getElementById('root'));

export default TripEditor;
